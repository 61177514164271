<section role="terminal-content">
  <pre #preEl></pre>
</section>

<pre [innerHTML]="statusStr"></pre>
<pre
  class="text-loader"
  *ngIf="cmdIsLoading()">Loading <span>.</span><span>.</span><span>.</span></pre>
<aside>
  <span class="cmd-caret">❯</span>

  <textarea
    #textareaEl
    id="cmdField"
    [value]="cmdValue()"
    (input)="cmdValue.set(textareaEl.value)"
    tabindex="0"
    (keydown.arrowup)="historyCmd($event, 'prev')"
    (keydown.arrowdown)="historyCmd($event, 'next')"
    (keydown.enter)="submitCmd($event)"
    autocomplete="off"
    autocorrect="off"
    autocapitalize="off"
    spellcheck="false"></textarea>
</aside>

<label for="cmdField"></label>
